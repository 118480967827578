import {HTTPClient as HTTPClientCore, IRequestConfig} from "modules/utils/HTTPClient";
import axios, {AxiosError} from "axios";
import {isObject, set} from "lodash";
import ApiError from "./ApiError";
import {API_URL, JSON_URL} from "modules/constants";
import {IApiResponse, IDictionary} from "modules/types";
import {IHelp, IHelpState, ILeague, IPrediction, ISnapshot, IUser} from "modules/reducers";
import {
	IGetLeagueRankingsSuccess,
	IContactPayload,
	ICreateLeaguesPayload,
	IGetLeague,
	IGetLeagueRankingsPayload,
	IGetLeaguesForJoinSuccess,
	IGetLeaguesSuccess,
	IGetLeagueUsersPayload,
	IGetRankingsPayload,
	IGetRankingsSuccess,
	IGetUsersSuccess,
	IInviteUsersToLeague,
	ILadder,
	IPredictionsPayload,
	IRemoveLeagueUserPayload,
	IRequestResetPasswordPayload,
	IResetPasswordPayload,
	IRound,
	IRoundStat,
	ISquad,
	IUpdateLeaguesPayload,
	IUserLoginPayload,
	IUserRegisterPayload,
	IUserUpdatePayload,
	TCountry,
	IUserPreregister,
	IUserCheckTeamnamePayload,
} from "modules/actions";
import {CANCEL} from "redux-saga";
import {IAcceptTCPayload, IReactivatePayload} from "modules/actions/modals";
import {IUserGeoLocationAds} from "modules/reducers/ads";

class HTTPClient extends HTTPClientCore {
	/**
	 * Overridden method adds CancelToken symbol, that allow redux-saga'
	 * "takeLatest" function to cancel any requests automatically.
	 * http://fe-common-utils.s3-website-eu-west-1.amazonaws.com/classes/httpclient.html
	 */
	public makeRequest<T>(config: IRequestConfig): Promise<T> {
		const source = axios.CancelToken.source();

		const request = super.makeRequest<T>({
			...config,
			cancelToken: source.token,
		});

		return set<Promise<T>>(request, CANCEL, () => source.cancel());
	}
}

const onCatchNetworkError = ({response, message = "Network error"}: AxiosError<ApiError>) => {
	const data = response?.data;
	const error = isObject(data)
		? data
		: {
				errors: [new ApiError(message)],
		  };

	return Promise.reject(error).catch((err) => ApiError.CHECK(err as IApiResponse));
};

const APIClient = new HTTPClient({
	baseURL: API_URL,
	withCredentials: true,
	onCatchNetworkError,
});

const JSONClient = new HTTPClient({
	baseURL: JSON_URL,
});

type TUserResponse = IApiResponse<{user: IUser}>;
type TPredcitionsResponse = IApiResponse<{predictions: IPrediction[]}>;
type ICreateLeaguesResponse = IApiResponse<{league: ILeague}>;
type IGetLeaguesResponse = IApiResponse<IGetLeaguesSuccess>;
type IGetLeaguesForJoinResponse = IApiResponse<IGetLeaguesForJoinSuccess>;
type IJoinLeaguesResponse = IApiResponse<{league: ILeague}>;
type IGetLeagueResponse = IApiResponse<IGetLeague>;
type IUserUpdateResponse = IApiResponse<{user: IUser}>;
type IGetLeagueRankingsResponse = IApiResponse<IGetLeagueRankingsSuccess>;
type IGetLeagueUsersResponse = IApiResponse<IGetUsersSuccess>;
type IGetRankingsResponse = IApiResponse<IGetRankingsSuccess>;
type IUserGeolocationResponse = IApiResponse<IUserGeoLocationAds>;

interface IPredictionsAutopickPayload {
	predictions: {tournament: number}[];
}

export interface IGetLeagues {
	offset?: number;
	limit?: number;
}

export interface IGetLeaguesForJoin {
	search?: string;
	startRound?: number;
	page?: number;
	limit?: number;
}

export const Api = {
	JSON: {
		checksums: () => JSONClient.get<IDictionary<string>>("checksums.json"),
		countries: () => JSONClient.get<TCountry>("countries.json"),
		rounds: () => JSONClient.get<IRound[]>("rounds.json"),
		squads: () => JSONClient.get<ISquad[]>("squads.json"),
		round_stats: (id: number) => JSONClient.get<IRoundStat[]>(`stats/rounds/${id}.json`),
		help_pages: () => JSONClient.get<IHelpState>("help_pages.json"),
		faqs: () => JSONClient.get<IHelp[]>("faqs.json"),
		ladder: () => JSONClient.get<ILadder[]>("ladder.json"),
	},
	Auth: {
		login: (params: IUserLoginPayload) => APIClient.post<TUserResponse>("auth/login", params),
		register: (params: IUserRegisterPayload) =>
			APIClient.post<TUserResponse>("auth/register", params),
		preregister: (params: IUserPreregister) =>
			APIClient.post<TUserResponse>("auth/preregister", params),
		logout: () => APIClient.post<IApiResponse>("auth/logout"),
		requestPasswordReset: (params: IRequestResetPasswordPayload) =>
			APIClient.post<TUserResponse>("auth/password_reset/request ", params),
		passwordReset: (params: IResetPasswordPayload) =>
			APIClient.post<TUserResponse>("auth/password_reset ", params),
	},
	User: {
		user: () => APIClient.get<TUserResponse>("user"),
		update: (params: IUserUpdatePayload) =>
			APIClient.post<IUserUpdateResponse>("user/update", params),
		reactivate: (params: IReactivatePayload) => APIClient.post("user/activate", params),
		acceptTC: (params: IAcceptTCPayload) => APIClient.post("user/accept_terms", params),
		checkTeamname: (params: IUserCheckTeamnamePayload) =>
			APIClient.post(`user/check_username?username=${params.username}`),
		geoLocationAds: () => APIClient.get<IUserGeolocationResponse>("ads"),
	},
	Predictions: {
		predictions: (id: number) => APIClient.get<TPredcitionsResponse>(`predictions/${id}`),
		autopick: (predictions: IPredictionsAutopickPayload) =>
			APIClient.post<TPredcitionsResponse>("predictions_list/autopick", predictions),
		predictions_list: (payload: IPredictionsPayload) =>
			APIClient.post<TPredcitionsResponse>("predictions_list", payload),
	},
	Rankings: {
		get: (params?: IGetRankingsPayload) =>
			APIClient.get<IGetRankingsResponse>(`rankings/overall/rankings`, params),
		gamebar: (round: number) =>
			APIClient.get<IApiResponse<ISnapshot>>(`rankings/gamebar`, {round}),
	},
	Contact: {
		contact: (params: IContactPayload) => APIClient.post("contact", params),
	},
	Leagues: {
		get: (params?: IGetLeagues) => APIClient.get<IGetLeaguesResponse>("leagues", params),
		getLeaguesForJoin: (params?: IGetLeaguesForJoin) =>
			APIClient.get<IGetLeaguesForJoinResponse>("league/show-for-join", params),
	},
	League: {
		create: (params: ICreateLeaguesPayload) =>
			APIClient.post<ICreateLeaguesResponse>("league", params),
		invite: (params: IInviteUsersToLeague) =>
			APIClient.post<ICreateLeaguesResponse>(`league/${params.leagueId}/invite`, {
				invites: params.users,
			}),
		get: (id: string) => APIClient.get<IGetLeagueResponse>(`league/${id}`),
		update: (params: IUpdateLeaguesPayload) =>
			APIClient.post<ICreateLeaguesResponse>(`league/${params.id}`, {
				name: params.name,
				privacy: params.privacy,
				startRound: params.startRound,
			}),
		join: (code: string) => APIClient.post<IJoinLeaguesResponse>(`league/${code}/join`),
		delete: (id: string) => APIClient.post<IApiResponse>(`league/${id}/delete`),
		leave: (id: string) => APIClient.post<IApiResponse>(`league/${id}/leave`),
		rankings: ({id, ...params}: IGetLeagueRankingsPayload) =>
			APIClient.get<IGetLeagueRankingsResponse>(`rankings/${id}/rankings`, params),
		getUsers: ({id, ...params}: IGetLeagueUsersPayload) =>
			APIClient.get<IGetLeagueUsersResponse>(`league/${id}/user/show-joined`, params),
		removeUser: ({id, userId}: IRemoveLeagueUserPayload) =>
			APIClient.post<IApiResponse>(`league/${id}/user/${userId}`),
	},
};

export * from "./ApiError";

export default Api;
