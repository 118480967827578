import styled from "styled-components";
import {
	Checkbox as DefaultCheckbox,
	CheckboxProps,
	FormControl,
	FormControlLabel,
	InputLabel,
	TextField,
	Select as DefaultSelect,
	Radio,
	FormLabel,
	SelectChangeEvent,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import React, {FC, PropsWithChildren} from "react";
import {TextFieldProps} from "@mui/material/TextField/TextField";
import {LocalizationProvider} from "@mui/lab";
import AdapterLuxon from "@mui/lab/AdapterLuxon";
import DatePicker from "@mui/lab/DatePicker";
import {USER_DOB_FORMAT} from "modules";
import {ReactComponent as CheckedCheckbox} from "assets/images/checkedCheckbox.svg";
import {ReactComponent as Checkbox} from "assets/images/checkbox.svg";
import {DateTime} from "luxon";
import {Exist} from "components/Exist";

export const ErrorText = styled.p`
	margin-bottom: 10px;
	font-size: 14px;
	color: ${({theme}) => theme.colors.error};
	font-family: ${({theme}) => theme.typography.fontFamily};
`;

export const FormField = styled(FormControl)`
	&& {
		margin-bottom: 20px;
		width: 100%;
		text-align: left;
	}

	&&& {
		input {
			padding: 11px;
			font-family: ${({theme}) => theme.typography.fontFamily};
		}

		fieldset {
			font-family: ${({theme}) => theme.typography.fontFamily};
			border-radius: 2px;
			border-color: ${({theme}) => theme.form.border.primary};
		}
		.Mui-error fieldset {
			font-family: ${({theme}) => theme.typography.fontFamily};
			border-color: #d32f2f;
			legend span {
				font-family: ${({theme}) => theme.typography.fontFamily};
				color: #d32f2f;
			}
		}

		label {
			font-family: ${({theme}) => theme.typography.fontFamily};
			&[data-shrink="false"] {
				top: -4px;
			}

			color: ${({theme}) => theme.form.border.primary};
		}
	}
`;

export const FormTextField = styled(TextField)`
	width: 100%;
	font-family: ${({theme}) => theme.typography.fontFamily};
`;

export const FormTitle = styled.p`
	font-size: 18px;
	line-height: 24px;
	font-weight: 700;
	margin-bottom: 20px;
	font-family: ${({theme}) => theme.typography.fontFamily};
`;

export const FormSelectIcon = styled(KeyboardArrowDownIcon)`
	margin-right: 0;
`;

export const RadioButton = styled(Radio)`
	&&& {
		padding: 6px;
		color: #000000;
		font-family: ${({theme}) => theme.typography.fontFamily};
	}
`;

export const RadioButtonControlLabel = styled(FormControlLabel)`
	span {
		font-size: 14px;
		color: #000000;
		font-family: ${({theme}) => theme.typography.fontFamily};
	}
`;

export const Label = styled(FormLabel)`
	&& {
		font-size: 14px;
		font-weight: 700;
		font-family: ${({theme}) => theme.typography.fontFamily};
	}
`;

export const Select = styled(DefaultSelect)`
	& > div {
		padding: 11px !important;
		font-family: ${({theme}) => theme.typography.fontFamily};
	}
	font-family: ${({theme}) => theme.typography.fontFamily};
`;

export const FormLink = styled.div`
	font-size: 14px;

	a {
		font-family: ${({theme}) => theme.typography.fontFamily};
		color: ${({theme}) => theme.form.link.primary};
		font-weight: 700;

		&:hover {
			text-decoration: underline;
		}
	}
`;

export const FormCheckbox = styled(DefaultCheckbox)`
	&& * {
		font-family: ${({theme}) => theme.typography.fontFamily};
		font-size: 30px;
		color: black;
		border-radius: 0;
	}
`;

export const FormCheckoutLabel = styled.span`
	display: block;
	font-size: 12px;
	line-height: 15px;
	text-align: left;
	font-family: ${({theme}) => theme.typography.fontFamily};
	a {
		font-family: ${({theme}) => theme.typography.fontFamily};
		text-decoration: underline;
	}
`;

export const ErrorMessage = styled.p`
	font-size: 14px;
	line-height: 17px;
	margin-top: 2px;
	color: #d32f2f;
	font-family: ${({theme}) => theme.typography.fontFamily};
`;

export const FormInput: React.FC<TextFieldProps> = ({
	required = true,
	variant = "outlined",
	...rest
}) => (
	<FormField>
		<FormTextField required={required} variant={variant} {...rest} />
		<Exist when={rest.inputProps?.errorplace === rest.name}>
			<ErrorMessage>{rest.inputProps?.errortext}</ErrorMessage>
		</Exist>
	</FormField>
);

interface IDatePicker {
	value: DateTime | null;
	onChange: (date: DateTime | null) => void;
	label?: string;
	error?: string;
}

export const DatePickerComponent: React.FC<IDatePicker> = ({
	value,
	onChange,
	label = "Date of birth (DD/MM/YYYY)",
	error,
}) => {
	return (
		<FormField>
			<LocalizationProvider dateAdapter={AdapterLuxon}>
				<DatePicker
					value={value}
					inputFormat={USER_DOB_FORMAT}
					onChange={onChange}
					label={label}
					maxDate={DateTime.fromJSDate(
						new Date(new Date().setFullYear(new Date().getFullYear() - 12))
					)}
					renderInput={(params: TextFieldProps) => (
						<TextField
							inputProps={{placeholder: USER_DOB_FORMAT}}
							required={true}
							{...params}
							name="birthday"
						/>
					)}
				/>
			</LocalizationProvider>
			<Exist when={!!error}>
				<ErrorMessage>{error}</ErrorMessage>
			</Exist>
		</FormField>
	);
};

interface IFormSelect {
	id: string;
	label: string;
	name: string;
	required?: boolean;
	defaultValue?: string;
	onChange?: (event: SelectChangeEvent<unknown>) => void;
	disabled?: boolean;
	errorPlace?: string;
	errorText?: string;
}

export const FormSelect: FC<PropsWithChildren<IFormSelect>> = ({
	id,
	label,
	name,
	required = true,
	defaultValue,
	children,
	onChange,
	disabled,
	errorPlace,
	errorText,
}) => (
	<FormField>
		<InputLabel id={id}>{label}</InputLabel>
		<Select
			defaultValue={defaultValue}
			onChange={onChange}
			labelId={id}
			disabled={disabled}
			name={name}
			required={required}
			IconComponent={FormSelectIcon}
			label={label}>
			{children}
		</Select>
		<Exist when={errorPlace === name}>
			<ErrorMessage>{errorText}</ErrorMessage>
		</Exist>
	</FormField>
);

interface IFormCheckbox {
	name: string;
	children: React.ReactElement;
	required?: boolean;
	defaultChecked?: boolean;
}

export const FormCheckboxWithLabel: React.FC<IFormCheckbox> = ({
	required = true,
	name,
	children,
	defaultChecked,
}) => {
	return (
		<FormField>
			<FormControlLabel
				control={
					<FormCheckbox
						defaultChecked={defaultChecked}
						required={required}
						name={name}
						icon={<Checkbox />}
						checkedIcon={<CheckedCheckbox />}
						inputProps={{"data-field": name} as CheckboxProps["inputProps"]}
					/>
				}
				label={children}
			/>
		</FormField>
	);
};
