import React, {Fragment} from "react";
import styled from "styled-components";
import {useSelector} from "react-redux";
import {getGameBarData} from "modules/selectors";
import {share, useSelectedRound} from "modules/utils";
import {ShareButton} from "components";
import TwitterIcon from "@mui/icons-material/Twitter";
import {ReactComponent as FacebookIcon} from "assets/images/icons/facebook.svg";
import {SectionType, ShareType} from "modules/enums";
import {isMobile} from "react-device-detect";
import ShareIcon from "@mui/icons-material/Share";

const GameBarContainer = styled.section`
	display: flex;
	align-items: center;
	justify-content: space-between;
	box-shadow: 0 5px 20px 0 rgba(35, 35, 35, 0.1);
	max-width: 1380px;
	margin: 10px 10px;
	height: 70px;
	background: #fff;
	border-radius: 10px;
	padding: 0 20px 0 12px;

	@media (min-width: 768px) {
		margin: 20px 0;
		height: 120px;
		border-radius: 4px;
		padding: 0 35px;
	}
`;

const RoundDataWrapper = styled.div`
	display: flex;
	align-items: center;
`;

const DataWrapper = styled.div`
	margin-right: 20px;
	@media (min-width: 768px) {
		margin-right: 30px;
	}
`;

const Title = styled.p`
	color: #010101;
	font-size: 14px;
	letter-spacing: 0;
	line-height: 14px;
	text-align: center;
	margin-bottom: 5px;

	span {
		display: none;
	}

	@media (min-width: 768px) {
		span {
			display: inline;
		}
	}
`;
const Value = styled.p`
	font-size: 16px;
	font-weight: bold;
	letter-spacing: 0;
	line-height: 12px;
	text-align: center;
	color: #010101;

	@media (min-width: 769px) {
		font-size: 22px;
		line-height: 16px;
		text-align: left;
	}
`;

const ShareWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-end;
`;

const RoundData: React.FC = () => {
	const gameBar = useSelector(getGameBarData);
	const round = useSelectedRound();

	const id = round?.id || "";

	return (
		<RoundDataWrapper>
			<DataWrapper>
				<Title>
					Round {id} <span>Score</span>
				</Title>
				<Value>{gameBar.roundPoints}</Value>
			</DataWrapper>
			<DataWrapper>
				<Title>
					Total <span>Score</span>
				</Title>
				<Value>{gameBar.overallPoints}</Value>
			</DataWrapper>
			<DataWrapper>
				<Title>
					Overall <span>Rank</span>
				</Title>
				<Value>{gameBar.overallRank}</Value>
			</DataWrapper>
		</RoundDataWrapper>
	);
};

const GameShare: React.FC = () => {
	const shareClick = (type: ShareType) => share({sc: type, section: SectionType.INVITE_FRIENDS});
	const isMobileWithCheck = isMobile && "share" in navigator;

	return (
		<ShareWrapper>
			{isMobileWithCheck ? (
				<ShareButton onClick={() => void shareClick(ShareType.MOBILE)} aria-label="share">
					<ShareIcon />
				</ShareButton>
			) : (
				<Fragment>
					<ShareButton
						onClick={() => void shareClick(ShareType.TW)}
						aria-label="share twitter">
						<TwitterIcon />
					</ShareButton>
					<ShareButton
						onClick={() => void shareClick(ShareType.FB)}
						aria-label="share facebook">
						<FacebookIcon />
					</ShareButton>
				</Fragment>
			)}
		</ShareWrapper>
	);
};

export const GameBar: React.FC = () => {
	return (
		<GameBarContainer>
			<RoundData />
			<GameShare />
		</GameBarContainer>
	);
};
