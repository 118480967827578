import React, {useCallback, useEffect, useRef} from "react";
import {
	ButtonsBox,
	CloseButton,
	Modal,
	ModalContainer,
	ModalContent,
	SuccessImg,
	Text,
	Title,
} from "components/Modals/common";
import {ReactComponent as CloseIcon} from "assets/images/icons/close.svg";
import {ReactComponent as BlackCheck} from "assets/images/icons/blackCheck.svg";
import {PrimaryButton, SecondaryButton} from "components/Buttons";
import {useClickOutside} from "modules/utils/hooks/useClickOutside";
import styled from "styled-components";
import {Flexible} from "components/Common";
import {filter} from "lodash";
import {SectionType, ShareType} from "modules";
import {share, useSelectedRound} from "modules/utils";
import TwitterIcon from "@mui/icons-material/Twitter";
import {ReactComponent as FacebookIcon} from "assets/images/icons/facebook.svg";
import {useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {hideSaveTipsModal} from "modules/actions/modals";
import {getIsOpenSaveTipsModal, getTournamentWithPredictionsSelector} from "modules/selectors";

const ShareContainer = styled.div`
	margin-top: 16px;
	display: flex;
	flex-direction: column;
	align-items: center;
`;

const ShareButton = styled.div`
	padding: 16px;
	cursor: pointer;
	width: 50px;
`;

export const SavedModal: React.FC = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const ref = useRef(null);
	const open = useSelector(getIsOpenSaveTipsModal);
	const round = useSelectedRound();
	const tournaments = useSelector(getTournamentWithPredictionsSelector)(round);
	const totalPicks = tournaments.length;
	const selectedPicks = filter(tournaments, (tournament) => tournament.prediction).length;

	const closeHandler = useCallback(() => {
		dispatch(hideSaveTipsModal());
	}, [dispatch]);

	useEffect(() => closeHandler, [closeHandler]);

	useClickOutside(ref, closeHandler);

	const shareClick = (type: ShareType) => share({sc: type, section: SectionType.INVITE_FRIENDS});

	const myLeaguesHandler = useCallback(() => {
		navigate("/leagues");
	}, [navigate]);

	if (!open) {
		return null;
	}

	return (
		<ModalContainer>
			<Modal ref={ref}>
				<CloseButton onClick={closeHandler} id="btn-close-error-modal">
					<CloseIcon />
				</CloseButton>
				<ModalContent>
					<SuccessImg>
						<BlackCheck />
					</SuccessImg>
					<Title>Tips Saved</Title>
					<Text>
						You have successfully saved
						<strong>{` ${selectedPicks}/${totalPicks} `}</strong>
						tips.
					</Text>
					<ButtonsBox>
						<SecondaryButton onClick={myLeaguesHandler}>My Leagues</SecondaryButton>
						<PrimaryButton onClick={closeHandler}>Edit Picks</PrimaryButton>
					</ButtonsBox>
					<ShareContainer>
						<span>Share with tips</span>
						<Flexible>
							<ShareButton onClick={() => void shareClick(ShareType.TW)}>
								<TwitterIcon />
							</ShareButton>
							<ShareButton onClick={() => void shareClick(ShareType.FB)}>
								<FacebookIcon />
							</ShareButton>
						</Flexible>
					</ShareContainer>
				</ModalContent>
			</Modal>
		</ModalContainer>
	);
};
