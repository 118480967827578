import React, {PropsWithChildren, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {requestGameData, requestGameJsonData} from "modules/actions";
import {getGameRequestJsonState, isLoggedIn} from "modules/selectors";
import {RequestState} from "modules";
import {Preloader} from "components";
import styled from "styled-components";
import {useSelectedRound} from "modules/utils";

const Container = styled.div`
	width: 100%;
`;

export const HOCGameData: React.FC<PropsWithChildren> = ({children}) => {
	const dispatch = useDispatch();
	const round = useSelectedRound();
	const requestJsonState = useSelector(getGameRequestJsonState);
	const isAuth = useSelector(isLoggedIn);

	useEffect(() => {
		if (isAuth) {
			dispatch(requestGameJsonData());
		}
	}, [dispatch, isAuth]);

	useEffect(() => {
		if (round && isAuth) {
			dispatch(requestGameData({roundId: round.id}));
		}
	}, [dispatch, round, isAuth]);

	return (
		<Container>
			{requestJsonState === RequestState.Received || !isAuth ? children : <Preloader />}
		</Container>
	);
};
