import {useEffect, useRef, useState} from "react";
import {useLocation} from "react-router-dom";
import {debounce} from "lodash";
import {useParams} from "react-router";
import {useSelector} from "react-redux";
import {getRoundByIdSelector} from "modules/selectors";
import {IRound} from "modules/actions";

export const usePrevious = <T = undefined>(value: T): T | undefined => {
	const ref = useRef<T>();

	useEffect(() => {
		ref.current = value;
	});

	return ref.current;
};

export const useMediaQuery = (query: string) => {
	const mediaMatch = window.matchMedia(query);
	const [matches, setMatches] = useState(mediaMatch.matches);

	useEffect(() => {
		const handler = (e: MediaQueryListEvent) => setMatches(e.matches);
		if ("addListener" in mediaMatch) {
			mediaMatch.addListener(handler);
		} else {
			(mediaMatch as MediaQueryList).addEventListener("change", handler);
		}

		return () => {
			if ("addListener" in mediaMatch) {
				mediaMatch.removeListener(handler);
			} else {
				(mediaMatch as MediaQueryList).removeEventListener("change", handler);
			}
		};
	});

	return matches;
};

export const useURLQuery = () => new URLSearchParams(useLocation().search);

export const useWindowSize = (delay = 300) => {
	const [windowSize, setWindowSize] = useState({
		width: 0,
		height: 0,
	});

	useEffect(() => {
		function handleResize() {
			setWindowSize({
				width: window.innerWidth,
				height: window.innerHeight,
			});
		}

		const debounced = debounce(handleResize, delay);
		window.addEventListener("resize", debounced);
		handleResize();

		return () => window.removeEventListener("resize", debounced);
	}, [delay]);

	return windowSize;
};
const getRoundId = (roundId?: string) => (roundId ? parseInt(roundId, 10) : undefined);

export const useSelectedRound = (): IRound | undefined => {
	const {roundId} = useParams<{roundId: string}>();
	const roundIdInt = getRoundId(roundId);
	return useSelector(getRoundByIdSelector)(roundIdInt);
};

export * from "./useBlocker";
